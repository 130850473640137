import * as React from "react"
//import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/main.scss"

const IndexPage = () => (
  <Layout>
    <SEO title="Dmitri's Awesome Side-projects" />
    <section className="banner">
      <StaticImage
        width={200}
        height={200}
        className="avatar"
        src="https://res.cloudinary.com/dbphvc4co/image/upload/v1616022345/Personal/3D_avatar-use_this2_ocrrdx.jpg"
        alt="Dmitri Avatar"
      />
      <h1 className="title">
        Made By Dmitri{" "}
        <span role="img" aria-label="sparkle emoji">
          ✨
        </span>
      </h1>
    </section>
    <section className="main-text">
      <p className="body-text">
        Hi there! My name is Dmitri Atrash, and I am a web designer based in
        Omaha, Nebraska.
      </p>
      <p className="body-text">
        From time to time, I like to make cool things, and that's why I whipped
        up this website to show off my fun side-projects.
      </p>
      <p className="body-text">
        Take a look around and let me know what you think. Maybe we can make
        something awesome together!
      </p>
    </section>
    <section className="project-links column">
      <div className="btn__container">
        <a href="https://www.dmitriatrash.com" target="_blank" rel="noreferrer">
          <button className="btn__primary">Personal Website</button>
        </a>
        <a href="https://dmitri.works" target="_blank" rel="noreferrer">
          <button className="btn__primary">React Resume</button>
        </a>
        <a href="https://dmitri.works" target="" rel="">
          <button className="btn__disabled" aria-disabled="true" disabled>
            Coming Soon!
          </button>
        </a>
      </div>
      <span className="check-back">
        Thanks for checking out my projects! Check back often, I’m always
        working on something.
      </span>
    </section>
  </Layout>
)

export default IndexPage
